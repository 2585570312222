footer {
    position: fixed;
    bottom: 5%;
    width: 100%;
  }
  
  .App {
    text-align: center;
  }
  
  .data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
  }
  
  .navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
  }
  
  .navbarStyle {
    padding: .5rem 1rem !important;
  }
  
  .table-responsive {
    max-height: 39rem !important;
  }

  
  .iconText {
    margin: 0 .5rem;
  }
  
  .profileButton {
    margin: .5rem .5rem;
  }
  
  .float{
    position:fixed;
    width:60px;
    height:60px;
    bottom:40px;
    right:40px;
    background-color:#0C9;
    color:#FFF;
    border-radius:50px;
    text-align:center;
    box-shadow: 2px 2px 3px #999;
  }
  
  .my-float{
    margin-top:22px;
  }