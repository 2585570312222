.App {
  font-family: sans-serif;
  /* text-align: center; */
}

.rdt_Table {
  margin-bottom: 1rem;
  border-bottom: 2px solid black;
}
.rdt_TableRow {
  background-color: rgb(255, 255, 255);
}
.rdt_TableRow:hover{
  background-color: rgb(231, 252,241);
  color: black;
  font-weight: bold;
}
.rdt_TableCol {
  color: black;
  font-weight: bolder;
}
.rdt_TableCol_Sortable {
}
.rdt_TableCell {
}
.rdt_TableHeader {
  background-color: transparent;
}
.rdt_TableFooter {
  
}
.rdt_TableHead {
  border-top: 2px solid black;
}
.rdt_TableHeadRow {
  background-color: rgb(202, 202, 202);
}
.rdt_TableBody {
  border-top: 2px solid black;
  
}
.rdt_ExpanderRow {
}
