.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    background-color: #fff;
    padding: 0px;
  }
  .grid-item {
    background-color: #fff;
    border: 1px solid #eee;
    font-size: 30px;
    text-align: center;
    height: 100vh;
  }
  .grid-item-th {
    background-color: rgb(151, 151, 151);
    border: 1px solid #eee;
    font-size: 20px;
    text-align: center;
    color: #fff; 
  }