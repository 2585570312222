* {
  outline: 0;
}

.App {
  font-family: sans-serif;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

input {
  display: block;
  margin: 1em;
  margin-bottom: 5em;
  padding: 1em;
  border-radius: 8px;
  border-style:solid;
  border: 3px solid #e4e6e8;
  transition: 0.1s ease;
  width: 100%;
}

textarea{
  display: block;
  margin: 1em;
  padding: 1em;
  border-radius: 8px;
  border-style: solid;
  border: 3px solid #e4e6e8;
  transition: 0.1s ease;
  width: 100%;
}

.textareasendreply{
  display: block;
  margin-bottom: 7px;
  padding:1em;
  border-radius: 8px;
  border-style: solid;
  border: 3px solid #e4e6e8;
  transition: 0.1s ease;
  width: 100%;
}

button {
  display: block;
  margin: 1em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:0em;
  font-weight: bold; 
}

span{
  display: block;
  margin: 1em;
  font-size: large;

}

input:hover {
  border-color: rgba(9,144,117,255);
}
textarea:hover {
  border-color: rgba(9,144,117,255);
}

button:hover {
  background-color: rgba(9,144,117,255);
  color: white;
}

.afterResolvedButtonStyle{
  display: block;
  margin: 0.2em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: #f7f7f7;
  color: #bbb;
  padding:1em;
  font-weight: bold;
  max-width: fit-content; 
  width: 100%;
}

.afterResolvedButtonStyle:hover{
  background-color: #f7f7f7;
  color: #bbb;
}

.submitFeedbackButton {
  display: block;
  margin: 1em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:1em;
  font-weight: bold; 
}

.SubmitFormButton {
  display: block;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:1em;
  font-weight: bold; 
}

.DisabledSubmitFormButton {
  display: block;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: #f7f7f7;
  color: #bbb;
  padding:1em;
  font-weight: bold; 
}

.DisabledSubmitFormButton:hover{
  background-color: #f7f7f7;
  color: #bbb;
}

.SendReplyFormCCButton {
  display: block;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:1em;
  font-weight: bold; 
}

.SendReplyFormReopenButton {
  display: block;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:1em;
  font-weight: bold;
  width:100%; 
}

.afterResolvedButtonStyleReopen{
  display: none;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: #f7f7f7;
  color: #bbb;
  padding:0.1em;
  font-weight: bold; 
}

.afterResolvedButtonStyleReopen:hover{
  background-color: #f7f7f7;
  color: #bbb;
}

.DisabledSendReplyFormCCButton {
  display: block;
  margin: auto;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:1em;
  font-weight: bold; 
}

.DisabledSendReplyFormCCButton:hover{
  background-color: #f7f7f7;
  color: #bbb;
}

.ccListInput{
    display: block;
    margin: 0.1em;
    padding: 0.2em;
    border-radius: 3px;
    border-style:solid;
    border: 3px solid #e4e6e8;
    transition: 0.1s ease;
    width: 100%;
}

.saveCCButton {
  display: block;
  margin-left:0.3em ;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255);
  color:white;
  padding:0.3em;
  font-weight: bold;
}
.saveCCButton:hover{
  background-color: #099075;
  color: #fff;
}

.DisabledSendReplyFormCCButton {
  display: block;
  margin-left: 0.3em;
  border-radius: 8px;
  border-style: none;
  border: 1px solid #e4e6e8;
  transition: 0.1s ease;
  background-color: rgba(12,180,146,255); 
  color:white;
  padding:0.3em;
  font-weight: bold; 
}

.DisabledSaveCCButton:hover{
  background-color: #f7f7f7;
  color: #bbb;
}
