.body{
  width:100%;
  overflow-y: scroll;
  height: 100%;
}

Nav.Link{
  font-size: xx-large;
}

.navbar{
    margin:0;
    padding:0;
    background-color:#261e57;
    color:white;
    height:fit-content;
    width:100%;
  }
  
  .logo {
    margin-left: 5vw;
    padding: 0;
  }

  .accounticon{
    margin:auto;
    padding:0;
    z-index: 9999;
    
  }

  .active{
    color:#fff;
  }

  .signout_button{
    margin-left:0;
    margin-right:5vw;
  }

  .signin_button{
    margin-left:0;
    margin-right:5vw;
  }

/******* Page Layot ***********/
.maincontent{
  height:300px;
  width:100%;
  margin:0;
  padding:0;
  background-color: #5c5b5b;
}
.column-sidebar{
  float: left;
  width: 1.5%;
  height: 92vh; 
  border:1px solid black;
  background-color: #eee;
}
.column-conversation {
  float: left;
  width: 45%;
  padding: 5px;
  border:1px solid rgba(92,103,112,255);
  text-align:center;
  position:relative;
  margin-right:auto;
  overflow: scroll;
  height: 92vh;
  overflow-x: hidden;
}

.column-cif {
  position: relative;
  width: 19%;
  border:1px solid rgba(92,103,112,255);
  text-align:center;
  overflow:scroll;
  flex-wrap: wrap;
  background-color: white;
  height:92vh;
  overflow-x: hidden;
}

.column-issue-titles {
    float: left;
    width: 36%;
    padding-left: 0em;
    border:1px solid rgba(92,103,112,255);
    text-align:center;
    overflow: hidden;
    padding-top:0;
    height:92vh;
    overflow:scroll;
    overflow-x: hidden;
  
}
/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
    padding-right:1.5em;  
}

#row:focus{
  background-color: rgb(207, 255, 237);
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width:600px) {
  .column {
    width: 100%;
  }
}

/***** Issue Title related ********/


  .issue-title {
    float: left;
    width: 80%;
    border-bottom:1px solid #ddd;
    height: 4em;
    margin:0;
    display:inline;
    text-align:left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    }
 
  .issue-title-selected {
    background-color: rgb(227, 227, 227);
    border-bottom: 1px solid rgb(166, 166, 166);
    text-align: left;
    display: flex;
    text-overflow:ellipsis;
    
  }

/****** Issue Status box ********/
* {
    box-sizing: border-box;
}
.issue-status{
    float: left;
    width: 15%;
    padding: 1em;
    border-bottom:1px solid #fff;
    text-align:center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: x-small;
}
.issue-status-new{
    padding:0.2em;
    background-color:rgb(255, 210, 210);
    float:right;
    border:1px solid #fff;
    height: fit-content ;
    width:10em;
    text-align: center;
    font-size:small;
    font-weight: bold;
    overflow: hidden;
    text-overflow:ellipsis;
}
  
.issue-status-rejected{
    padding:0.2em;
    background-color:rgb(232, 233, 235);
    float:right;
    border:1px solid #fff;
    height: fit-content ;
    width:10em;
    text-align: center;
    font-size:small;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}

.issue-status-agent-replied{
    padding:0.2em;
    background-color:rgb(255, 232, 189);
    float:right;
    border:1px solid #fff;
    height: fit-content ;
    width:10em;
    text-align: center;
    font-size:small;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
}
  
  
/*****  Conversation *****/
.convbody{
    display: flex;
    flex-direction: column;
    height: 65vh;
    overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.convbody::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.convbody {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}

.conversation-container {
    display:table;
    grid-template-columns: auto;
    width:100%;
    background-color: #ffffff;
    white-space: pre-line;
}

.user-msg-outer-container{
    --u:rgb(231, 252,241);
    border:none ;
    display: table;
    grid-template-columns: auto;
    width:100%;
    padding:0.5em;
}

.feedback-msg-outer-container{
  --u:#fff;
  border:none ;
  display: table;
  grid-template-columns: auto;
  width:100%;
  padding:0.5em;
}
  
.agent-msg-outer-container{
    --a:rgb(245, 245,245);
    border:none;
    display: table;
    grid-template-columns: auto;
    width:100%;
    position:relative;
    padding:0.5em;
    border:none;
    direction: ltr;
}
 
  
  .user-msg-inner{
    border: 2px solid rgb(137, 233, 183);
    background-color: rgb(231, 252,241);
    padding: 0.5em;
    margin: 0.2em 0;
    max-width:80%;
    width:80%;
    border-radius: 0 1em 1em 1em;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 100%;
    word-break: break-all;
  }

  .feedback-msg-inner{
    border: 2px solid gray;
    background-color: #fff;
    padding: 0.5em;
    margin: 0.2em 0;
    max-width:80%;
    width:80%;
    border-radius: 0 1em 1em 1em;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
  }

  .agent-msg-inner{
    border: 2px solid rgb(221, 221, 221);
    background-color: rgb(245, 245, 245);
    padding: 0.5em;
    margin: 0.2em 0;
    max-width:80%;
    width:80%;
    position:relative;
    border-radius: 1em 1em 0 1em;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: left;
    float:right;
    overflow: hidden;
    word-wrap: break-word;
    max-width: 100%;
    word-break: break-all;
  }
  
  .replymsg{
    position:absolute;
    bottom:0;
    display:contents;
    min-height: 18%;
    margin-right:5vw;
    width:100%;
}

/*********** span time ********/

.time-user{
    display:inline-block;
    position:relative;
    text-align: right;
    color: var(--u);
    font-size: small;
    background-color: rgb(231, 252,241);
}

.user-msg-inner:hover{
    --u:rgb(146, 146, 146);
}
.time-agent{
    display:inline-block;
    position:relative;
    text-align: right;
    color: var(--a);
    font-size: small;
    background-color: rgb(245, 245,245);
}

.agent-msg-inner:hover{
    --a:rgb(146,146,146);
}

.agent-name{
    display:inline;
    position:relative;
    text-align: left;
    color: rgb(146, 146, 146);
    font-size: small;
    background-color: rgb(245, 245,245);
}
.user-name{
    display:inline;
    position:relative;
    text-align: left;
    color: rgb(146, 146, 146);
    font-size: small;
    background-color: rgb(231, 252,241);
}

/******* tags ******/
.tags{
    display: flex;
    background-color:rgb(240, 240, 240);
    float:left;
    border:none;
    margin:0.2em;
    padding: 0.3em;
    text-align: center;
    font-size:small;
    width:max-content;
    height:3vh;
    font-size: small;
}
.tagarea{
    display:table;
    flex-direction: column;
    height: 4vh;
    overflow: scroll;
    border-bottom: 1px solid rgba(92,103,112,255);
    text-align: right;
    width:100%;
}
.issueid{
    display:flex;
    float:right
}

/******* CIF ********/

 thead {
    position: sticky;
    top: 0;
    background: rgba(92,103,112,255); 
    color:white;
    font-weight: bolder;
    border-top: 2px solid rgba(92,103,112,255);
} 

.linkText {
    color: black;
}

.linkText:hover {
    color: green;
}

/*code to change background color*/
.linkText.active {
    background-color: #C0C0C0;
    color: red;
}

/****** Create Issue Body ******/

.column-createIssue {
    width: 100%;
    padding: 5px;
    border:1px solid #ddd;
    position:relative;
    margin: auto;
    overflow: scroll;
    height: 92vh;
    overflow-x: hidden;
}

/****** Send Replies *******/

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 1em;
  background-size: contain;
  background-repeat:  no-repeat;
  background-position: center;
  background-image:url(../images/pin.png);
  width:10%;
}

.file-preview {
margin: 0 10px;
}

.sendreplybutton{
  transition: 0.1s ease;
  width:100%;
  padding:1em;
  margin-right: 0.2em;
  background-color: rgba(12,180,146,255);
  color: white;
  font-weight: bolder;
}
.sendreplybutton:hover{
  background-color: rgba(9,144,117,255);
}


/********* Latest CIF CSS **********/
.outer-container{
  padding:0.5em;
}

.outer-container-fixed-attributes{
  padding-bottom:1em; 
}

.key-wrapper{
  display:grid;
  grid-template-columns: 1fr 6fr;
  grid-gap: 2px;
  padding:0.5em;
  text-align: left;
  border:1px solid gray;
}

.key{
  color:gray;
  font-size:13px;
  font-family: "Siemens Sans",Arial,sans-serif;
}

.value{
  color: #7e7e7e;
  font-weight: bolder;
  font-size: 14px;
  font-family: "Siemens Sans",Arial,sans-serif;
}

.key-wrapper2{
  display:grid;
  grid-template-columns: 1fr 6fr;
  grid-gap: 2px;
  padding:0.5em;
  text-align: left;
  border:1px solid gray;
  margin: 1em .5em 0 .5em;
}

.circle {
    font: 10px Arial, sans-serif;
    width: 2em;
    height: 2em;
    box-sizing: initial;

    background: rgba(9,144,117,255);
    border: 0.1em solid rgba(9,144,117,255);
    color: #fff;
    font-weight: bolder;
    text-align: center;
    border-radius: 50%;    

    line-height: 2em;
    box-sizing: content-box;
    margin:auto; 
}

.cificons {
  font: small ;
  width: 2em;
  height: 2em;
  box-sizing: initial;

  background: rgba(9,144,117,255);
  border: 0.1em solid rgba(9,144,117,255);
  color: #fff;
  font-weight: bolder;
  text-align: center;
  border-radius: 50%;    

  line-height: 2em;
  box-sizing: content-box;
  margin:auto; 
}

/********** FeedbackForm******/
.feedbackForm{
  padding-top: 1em;
  border-top: 0.3em solid rgba(92,103,112,255);
  height:auto;
  width:100%;
  overflow:hidden;
}
typography{
  color:'orange';
}